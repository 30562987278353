<template>
  <v-row>
    <v-col cols="12">
      <label class="description-label">Continut</label>
    </v-col>
    <v-col cols="12">
      <Tinymce v-model="text"/>
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import Tinymce from '@/components/general-form/Tinymce'

export default {
  name: 'BpDescription',
  components: {Tinymce},
  props: {
    value: {}
  },
  computed: {
    text: {
      get () {
        return this.value
      },
      set (newValue) {
        if (typeof newValue === 'undefined') {
          this.$emit('input', '')
        } else {
          this.$emit('input', newValue)
        }
      }
    }
  }
}
</script>

<style>
.description-label {
  font-weight: 700;
  font-size: .875rem;
  text-transform: uppercase;
}
</style>
